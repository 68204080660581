export const LOAD_IMAGE = 'LOAD_IMAGE';
export const LOAD_IMAGE_SUCCESS = 'LOAD_IMAGE_SUCCESS';
export const UPDATE_PIXEL_SIZE = 'UPDATE_PIXEL_SIZE';
export const UPDATE_BRUSH_SIZE = 'UPDATE_BRUSH_SIZE';
export const UPDATE_CONTRAST = 'UPDATE_CONTRAST';
export const UPDATE_BRIGHTNESS = 'UPDATE_BRIGHTNESS';
export const UPDATE_SATURATION = 'UPDATE_SATURATION';
export const UPDATE_COLOR_COUNT = 'UPDATE_COLOR_COUNT';
export const ENABLE_PAINT = 'ENABLE_PAINT';
export const UPDATE_PAINT_COLOR = 'UPDATE_PAINT_COLOR';
export const UPDATE_TOOL_TYPE = 'UPDATE_TOOL_TYPE';